import * as React from "react"
import Layout from "../components/layout"
import { Link } from 'gatsby'
import "@fontsource/raleway/300.css"
import "@fontsource/raleway/500.css"
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import Hero from '../components/hero'
import { LinkSection, RightSection, LeftSection, HomeButton } from '../components/standardtile'
import { HighlightText } from '../components/generalstyling'
import { Helmet } from "react-helmet"

// styles
const MainBodySection = styled.article`
  max-width: 960px;
  margin: 0 auto 50px;

  > h4 {
    max-width: 600px;
    margin: 30px auto 30px auto;

    @media only screen and (max-width: 900px) {
      padding: 0 20px;
    }
  }
}
`;


// markup
const ComposersPage = () => {
  return (
    <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Composers | The Opera 101</title>
          <html lang="en-us" />
          <link rel="canonical" href="https://theopera101.com/composers" />
          <meta name="description" content="Learn about some of the most significant opera composers including Verdi, Puccini and Britten." />
        </Helmet>
        <Hero title="The Composers"/>
        <MainBodySection>
          
          <HighlightText>Since its beginnings in 16th Century Italy, operas have been written the world over by men and women with different political, cultural and emotional backgrounds. Well, actually... there haven't been many women, but over the last few decades Kaija Saariaho and Judith Weir amongst others have finally started to address the huge imbalance. The Opera 101 currently covers <Link to="/composers/puccini">Giacomo Puccini</Link> and <Link to="/composers/verdi">Giuseppe Verdi</Link> in depth but they are merely two of many great composers. For more historic information check out our <Link to="/operaabc/history">Opera History</Link>. Know all this already?</HighlightText>        
          
          <LinkSection>
            <div>
              <LeftSection sizedimage="180px">
                <Link to="/composers/puccini"><StaticImage src="../images/composers/puccini.jpg" alt="Giacomo Puccini"/></Link>
              </LeftSection>
              <RightSection>
                <h3><Link to="/composers/puccini">Giacomo Puccini</Link></h3>

                <p>The final great Romantic composer bringing 20th Century influences into his later works. His works have thrilling melodies, sumptuous orchestrations and gloriously melodramatic stories.</p>

                <HomeButton><Link to="/composers/puccini">Learn More ></Link></HomeButton>
              </RightSection>
            </div>
            <div>
              <LeftSection sizedimage="180px">
                <Link to="/composers/verdi"><StaticImage src="../images/composers/verdi.jpg" alt="Giuseppe Verdi"/></Link>
              </LeftSection>
              <RightSection>
                <h3><Link to="/composers/verdi">Giuseppe Verdi</Link></h3>

                <p>Verdi dominated Italian opera for much of the 19th Century and he still dominates our opera stages today.</p>

                <HomeButton><Link to="/composers/verdi">Learn More ></Link></HomeButton>
              </RightSection>
            </div>
          </LinkSection>
        </MainBodySection>
    </Layout>
  )
}

export default ComposersPage
